import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies';
import { Provider } from 'react-redux';
import configureStore from './store';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import Loader from 'components/Loader';
import 'utils/i18next';

const isODOO = process.env.REACT_APP_ODOO;
const isOdooDashboard = process.env.REACT_APP_ODOO_DASHBOARD;
const App = lazy(() => {
  if (isODOO) {
    return import('./OdooApp');
  } else if (isOdooDashboard) {
    return import('./DashboardApp');
  } else {
    return import('./App');
  }
});

let element;

const user = cookie.load('PMCS-User-Profile');

// User Roles for Testing ONLY -----
// if (user) {
//   user.UserRoles = [
//     ...user.UserRoles,
//     'PCU TeamLeader',
//     'MOI Executive Dashboard',
//     'Tahakom Executive Dashboard',
//   ];
// }
// ---------------------------------

const initState = { user: { data: user || {}, userToken: 'VALID', accessToken: {} } };
const store = configureStore(initState);

const root = isOdooDashboard ? 'root1' : 'root';

window.__initReactModule = () => {
  element = document.getElementById(root);
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </Provider>
    </React.StrictMode>,
    element
  );
};

window.__destroyReactModule = () => {
  if (element) {
    ReactDOM.unmountComponentAtNode(element)
  }
};

window.__initReactModule();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
