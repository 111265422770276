export const accessControlMapping = {
  '/site-dispatch': [
    'fosupervisor',
    'team leader',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'wim supervisor',
  ],
  '/site-plan': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'ms manager',
    'ms teamleader',
    'ms supervisor',
    'cctv supervisor',
    'cctv manager',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'crane teamleader',
  ],
  '/attendance-reports': [
    'wim supervisor',
  ],

  '/system-integration': [
    'eqaitadmin',
  ],

  // '/implementation-review-reports': [
  //   'implementationReport',
  // ],
  '/task-review': [
    'fosupervisor',
    'team leader',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'crane teamleader'
  ],
  // '/configuration/resources': [
  //   'fosupervisor',
  //   'eqaitadmin',
  //   'pcu manager',
  //   'pcu supervisor',
  //   'pcu teamleader',
  //   'ms manager',
  //   'ms supervisor',
  //   'ms teamleader',
  //   'cctv manager',
  //   'cctv supervisor',
  //   'cctv teamleader',
  //   'vim manager',
  //   'vim supervisor',
  //   'vim teamleader',
  //   'wim supervisor',
  //   'operation regional manager',
  //   'rasedadmin'
  // ],
  '/candidate-list': [
    'moi manager',
    'moi regional manager',
    'tahakom manager',
    'tahakom reviewer'
  ],
  '/dashboard': [
    'moi executive dashboard',
    'executive dashboard',
  ],
  '/uncancel-task': [
    'rasedadmin',
  ],
  '/dashboardv2': [
    'tahakom executive dashboard',
    'executive dashboard',
  ],
  '/tech-support-dashboard': [
    'rasedadmin',
  ],
  '/operation-system/systems': [
    'rasedadmin',
    'operation regional manager',
    'rasedadmin',
    'pcu supervisor'
  ],
  '/operator-list/searchTasks': [
    'rasedadmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
  ],
  '/operator-list/dayOpenClose': [
    'rasedadmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
  ],
  '/site-gallery': [
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
  ],
  '/search-location': [
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'cctv manager',
    'audit'
  ],
  '/reports/taskDetailsReportPCU': [
    'eQAITAdmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader', ,
    'rased reports',
  ],
  '/reports/taskDetailsReportMS': [
    'eQAITAdmin',
    'eqaitadmin',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'rased reports',
  ],
  '/reports/taskDetailsReportCCTV': [
    'eQAITAdmin',
    'eqaitadmin',
    'cctv manager',
    'cctv supervisor',
    'cctv teamLeader',
    'rased reports',
  ],
  '/reports/taskDetailsReportVIM': [
    'eQAITAdmin',
    'eqaitadmin',
    'vim teamleader',
    'vim supervisor',
    'vim manager',
    'rased reports',
  ],
  '/reports/autoSiteplanDetails': [
    'eQAITAdmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader', ,
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamLeader',
    'vim teamleader',
    'vim supervisor',
    'vim manager',
  ],
  '/reports/autoSiteplanSummary': [
    'eQAITAdmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader', ,
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamLeader',
    'vim teamleader',
    'vim supervisor',
    'vim manager',
  ],
  '/configuration/allUsers': [
    'fosupervisor',
    'eqaitadmin',
    'administrator',
    'egate lite admin',
    'tpd admin',
    'sfrs admin',
  ],
  '/configuration/shifts': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/teams': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/pcu': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/sites': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/auto-site-plan': [
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms teamleader',
    'ms supervisor',
    'cctv supervisor',
    'cctv manager',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'planner'
  ],
  '/implementation-reports/poleAuditReport': [
    'administrator',
    'rasedadmin'
  ],
  '/implementation-reports/IltMini': [
    'administrator',
    'rasedadmin'
  ],
  '/implementation-reports/handoverReviewReport': [
    'administrator',
    'rasedadmin'
  ],
  '/operation-system/systems': [
    'rasedadmin',
    'operation regional manager',
    'rasedadmin',
    'pcu supervisor'
  ],
  '/operator-list/searchTasks': [
    'rasedadmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
  ],
  '/operator-list/dayOpenClose': [
    'rasedadmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
  ],
  '/site-gallery': [
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
  ],
  '/search-location': [
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'cctv manager',
    'audit'
  ],
  '/reports/taskDetailsReportPCU': [
    'eQAITAdmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader', ,
    'rased reports',
  ],
  '/reports/taskDetailsReportMS': [
    'eQAITAdmin',
    'eqaitadmin',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'rased reports',
  ],
  '/reports/taskDetailsReportCCTV': [
    'eQAITAdmin',
    'eqaitadmin',
    'cctv manager',
    'cctv supervisor',
    'cctv teamLeader',
    'rased reports',
  ],
  '/reports/taskDetailsReportVIM': [
    'eQAITAdmin',
    'eqaitadmin',
    'vim teamleader',
    'vim supervisor',
    'vim manager',
    'rased reports',
  ],
  '/reports/autoSiteplanDetails': [
    'eQAITAdmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader', ,
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamLeader',
    'vim teamleader',
    'vim supervisor',
    'vim manager',
  ],
  '/reports/autoSiteplanSummary': [
    'eQAITAdmin',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader', ,
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamLeader',
    'vim teamleader',
    'vim supervisor',
    'vim manager',
  ],
  '/configuration/allUsers': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/shifts': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/teams': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/pcu': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/configuration/sites': [
    'fosupervisor',
    'eqaitadmin',
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'operation regional manager',
    'ms manager',
    'ms supervisor',
    'ms teamleader',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'rasedadmin'
  ],
  '/auto-site-plan': [
    'pcu manager',
    'pcu supervisor',
    'pcu teamleader',
    'ms manager',
    'ms teamleader',
    'ms supervisor',
    'cctv supervisor',
    'cctv manager',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'planner'
  ],
};

export const restrictedAccessControlMapping = {
  '/rased-home': [
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'maintenancesupervisor',
    'pcu teamleader',
    'ms manager',
    'ms teamleader',
    'ms supervisor',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'planner'
  ],
  '/implementation': [
    'pcu manager',
    'pcu supervisor',
    'wim supervisor',
    'pcu teamleader',
    'ms manager',
    'ms teamleader',
    'ms supervisor',
    'operation regional manager',
    'cctv manager',
    'cctv supervisor',
    'cctv teamleader',
    'vim manager',
    'vim supervisor',
    'vim teamleader',
    'planner'
  ],
  // '/site-plan': [
  //   'hideoldsiteplan',
  // ]
}

// export default accessControlMapping;
